import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React from 'react'

const PreviewCompatibleImage = ({ imageInfo }) => {
  const {
    alt = '',
    childImageSharp,
    image,
  } = imageInfo

  if (image?.childImageSharp)
    return (
      <GatsbyImage
        alt={alt}
        image={image.childImageSharp.gatsbyImageData}
        style={imageInfo.imageStyle}
      />
    )

  if (childImageSharp)
    return (
      <GatsbyImage
        alt={alt}
        image={childImageSharp.gatsbyImageData}
        style={imageInfo.imageStyle}
      />
    )

  if (image && typeof image === 'string')
    return (
      <img
        alt={alt}
        src={image}
        style={imageInfo.imageStyle}
      />
    )

  return null
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.objectOf(PropTypes.object),
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    imageStyle: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
}

export default PreviewCompatibleImage
