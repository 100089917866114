// Modules
import PropTypes from 'prop-types'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

// Components
import PreviewCompatibleImage from './PreviewCompatibleImage'

// Scripts
import { mediumUp } from 'scripts/media-query'

// PropTypes
const propTypes = {
  content: PropTypes.node,
  header: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  imageAlt: PropTypes.string.isRequired,
  isReversed: PropTypes.bool,
}

const SideBySide = ({
  content,
  header,
  image,
  imageAlt,
  isReversed,
}) => {
  const theme = useTheme()
  const SideBySideStyles = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    .image-side,
    .content-side {
      width: 100%;
      margin: ${theme.space.betweenRows} 0;
      text-align: center;

      ${mediumUp} {
        width: 50%;
        margin: 0;
      }
    }

    .image-side {
      ${mediumUp} {
        order: ${isReversed ? 1 : 0};
      }
    }
    
    .content-side {
      ${mediumUp} {
        order: ${isReversed ? 0 : 1};
        text-align: left;
      }
    }

    .image-wrapper {
      img { width: auto; }

      ${mediumUp} {
        padding-right: ${theme.space.betweenColumns};
        
        &.reversed {
          padding-right: 0;
          padding-left: ${theme.space.betweenColumns};
        }
      }
    }

    .content-wrapper {
      ${mediumUp} {
        padding-left: ${theme.space.betweenColumns};

        &.reversed {
          padding-right: ${theme.space.betweenColumns};
          padding-left: 0;
        }
      }
    }
  `

  return (
    <SideBySideStyles>
      <div className='image-side'>
        <div className={`image-wrapper${isReversed ? ' reversed' : ''}`}>
          <PreviewCompatibleImage
            imageInfo={{
              alt: imageAlt,
              image,
            }}
          />
        </div>
      </div>
      <div className='content-side'>
        <div className={`content-wrapper${isReversed ? ' reversed' : ''}`}>
          <h1>{header}</h1>
          {content && <ReactMarkdown>{content}</ReactMarkdown>}
        </div>
      </div>
    </SideBySideStyles>
  )
}

SideBySide.propTypes = propTypes
export default SideBySide
