// Modules
import PropTypes from 'prop-types'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

// Components
import Container from './Container'
import SideBySide from './SideBySide'

// Scripts
import { largeUp } from 'scripts/media-query'
import setImage from 'scripts/setImage'

// PropTypes
const propTypes = {
  heroData: PropTypes.shape({
    backgroundColor: PropTypes.shape({
      customColor: PropTypes.string,
      selectedColorOption: PropTypes.string.isRequired,
    }),
    content: PropTypes.node,
    curveNumber: PropTypes.number,
    fontColor: PropTypes.shape({
      customColor: PropTypes.string,
      selectedColorOption: PropTypes.string.isRequired,
    }),
    header: PropTypes.string.isRequired,
    imageData: PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      imageAlt: PropTypes.string,
    }),
    isReversed: PropTypes.bool,
  }),
}

const Hero = ({ heroData }) => {
  const {
    curveNumber,
    header,
    imageData,
    isReversed,
  } = heroData

  let img
  if (imageData?.image)
    img = setImage(imageData.image)

  const theme = useTheme()
  const background = heroData?.backgroundColor
    ? heroData?.backgroundColor?.customColor
      ? heroData.backgroundColor.customColor
      : heroData.backgroundColor.selectedColorOption
    : theme.color.darkGrey

  const fontColor = heroData?.fontColor
    ? heroData?.fontColor?.customColor
      ? heroData.fontColor.customColor
      : heroData.fontColor.selectedColorOption
    : theme.color.white

  const HeroStyles = styled.section`
    .hero-content {
      padding: calc(${theme.space.betweenRows} + 71px) 0 1rem;
      background-color: ${background};
      color: ${fontColor};

      ${largeUp} { padding: 164px 0 1rem; }
    }

    .hero-curve { margin-top: -5px; }
  `

  let curveShape
  switch (curveNumber) {
    case 1:
      curveShape = 'M0,192L1440,96L1440,0L0,0Z'
      break
    case 2:
      curveShape = 'M0,288L120,256C240,224,480,160,720,160C960,160,1200,224,1320,256L1440,288L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z'
      break
    case 3:
      curveShape = 'M0,256L60,234.7C120,213,240,171,360,133.3C480,96,600,64,720,69.3C840,75,960,117,1080,117.3C1200,117,1320,75,1380,53.3L1440,32L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z'
      break
    case 4:
      curveShape = 'M0,192L60,186.7C120,181,240,171,360,149.3C480,128,600,96,720,90.7C840,85,960,107,1080,122.7C1200,139,1320,149,1380,154.7L1440,160L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z'
      break
    case 5:
      curveShape = 'M0,224L60,224C120,224,240,224,360,197.3C480,171,600,117,720,117.3C840,117,960,171,1080,181.3C1200,192,1320,160,1380,144L1440,128L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z'
      break
    case 6:
      curveShape = 'M0,96L60,112C120,128,240,160,360,181.3C480,203,600,213,720,197.3C840,181,960,139,1080,112C1200,85,1320,75,1380,69.3L1440,64L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z'
      break
    case 7:
      curveShape = 'M0,160L60,154.7C120,149,240,139,360,165.3C480,192,600,256,720,240C840,224,960,128,1080,85.3C1200,43,1320,53,1380,58.7L1440,64L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z'
      break
    case 8:
      curveShape = 'M0,128L80,144C160,160,320,192,480,208C640,224,800,224,960,202.7C1120,181,1280,139,1360,117.3L1440,96L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z'
      break
    case 9:
      curveShape = 'M0,96L80,101.3C160,107,320,117,480,144C640,171,800,213,960,218.7C1120,224,1280,192,1360,176L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z'
      break
  }

  return (
    <HeroStyles>
      <div className='hero-content'>
        <Container>
          {
            img
              ? (
                <SideBySide
                  content={heroData.content}
                  header={header}
                  image={img}
                  imageAlt={imageData.imageAlt}
                  isReversed={isReversed}
                />
              )
              : (
                <>
                  <h1>{header}</h1>
                  {heroData?.content && <ReactMarkdown>{heroData.content}</ReactMarkdown>}
                </>
              )
          }
        </Container>
      </div>
      {curveShape && (
        <div className='hero-curve'>
          <svg
            viewBox='0 0 1440 320'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d={curveShape}
              fill={background}
              fillOpacity='1'
            />
          </svg>
        </div>
      )}
    </HeroStyles>
  )
}

Hero.propTypes = propTypes
export default Hero
