// Modules
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

// Components
import ReactMarkdown from 'react-markdown'

// Scripts
import { largeUp } from 'scripts/media-query'
import setImage from 'scripts/setImage'

// PropTypes
const propTypes = {
  cardData: PropTypes.shape({
    cardHeader: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    imageAlt: PropTypes.string.isRequired,
  }),
}

const CompanyCard = ({ cardData }) => {
  const theme = useTheme()
  const CompanyCardStyles = styled.div`
    margin: 0 auto ${theme.space.betweenSections};
    text-align: center;

    .card-image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  `

  const {
    cardHeader,
    content,
    imageAlt,
    image,
  } = cardData

  const img = setImage(image)

  return (
    <CompanyCardStyles>
      <div className='card-wrapper'>
        <h2>{cardHeader}</h2>
        <div className='card-image'>
          <img
            alt={imageAlt}
            src={img}
          />
        </div>
        <div className='card-content'>
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
      </div>
    </CompanyCardStyles>
  )
}

CompanyCard.propTypes = propTypes
export default CompanyCard
