export default function setImage(image) {
  let img
  if (image?.childImageSharp?.fluid?.originalImg)
    img = image.childImageSharp.fluid.originalImg

  if (typeof image === 'string')
    img = image

  return img
}
