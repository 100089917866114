// Modules
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

// Components
import IndexBody from 'components/page-bodies/IndexBody'
import Layout from 'components/global-components/Layout'
import MetaData from 'components/global-components/MetaData'

// PropTypes
const propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        companyCards: PropTypes.objectOf(PropTypes.object),
        heroData: PropTypes.objectOf(PropTypes.any),
        introSection: PropTypes.objectOf(PropTypes.string),
        metadata: PropTypes.objectOf(PropTypes.any),
      }),
    }),
  }),
}

const IndexPageTemplate = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        companyCards,
        heroData,
        introSection,
        metadata,
      },
    },
  } = data

  return (
    <Layout>
      <MetaData metaData={metadata} />
      <IndexBody
        companyCards={companyCards}
        heroData={heroData}
        introSection={introSection}
      />
    </Layout>
  )
}

IndexPageTemplate.propTypes = propTypes
export default IndexPageTemplate
export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      frontmatter {
        metadata {
          title
          description
          ogImage {
            childImageSharp {
              fluid {
                originalName
                originalImg
              }
            }
          }
        }
        introSection {
          header
          content
        }
        heroData {
          isReversed
          header
          curveNumber
          content
          backgroundColor {
            selectedColorOption
            customColor
          }
          fontColor {
            selectedColorOption
            customColor
          }
          imageData {
            imageAlt
            image {
              childImageSharp {
                fluid(maxWidth: 675) {
                  originalImg
                  originalName
                }
              }
            }
          }
        }
        companyCards {
          missionCard {
            cardHeader
            content
            imageAlt
            image {
              childImageSharp {
                fluid(maxWidth: 675) {
                  originalImg
                  originalName
                }
              }
            }
          }
          storyCard {
            cardHeader
            content
            imageAlt
            image {
              childImageSharp {
                fluid(maxWidth: 675) {
                  originalName
                  originalImg
                }
              }
            }
          }
          valueCard {
            cardHeader
            content
            imageAlt
            image {
              childImageSharp {
                fluid(maxWidth: 675) {
                  originalName
                  originalImg
                }
              }
            }
          }
          visionCard {
            cardHeader
            content
            imageAlt
            image {
              childImageSharp {
                fluid(maxWidth: 675) {
                  originalName
                  originalImg
                }
              }
            }
          }
        }
      }
    }
  }
`
