// Modules
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

// Components
import CompanyCard from '../CompanyCard'
import Container from '../global-components/Container'
import Hero from '../global-components/Hero'
import ReactMarkdown from 'react-markdown'

// PropTypes
const propTypes = {
  companyCards: PropTypes.shape({
    missionCard: PropTypes.objectOf(PropTypes.any).isRequired,
    storyCard: PropTypes.objectOf(PropTypes.any).isRequired,
    valueCard: PropTypes.objectOf(PropTypes.any).isRequired,
    visionCard: PropTypes.objectOf(PropTypes.any).isRequired,
  }),
  heroData: PropTypes.objectOf(PropTypes.any).isRequired,
  introSection: PropTypes.shape({
    content: PropTypes.node,
    header: PropTypes.string,
  }),
}

const IndexBody = ({
  companyCards,
  heroData,
  introSection,
}) => (
  <>
    <Hero heroData={heroData} />

    {(introSection?.header || introSection?.content) && (
      <section className='bottom-section-space'>
        <Container>
          {introSection?.header && <h2>INTRODUCTION SECTION</h2>}
          {introSection?.content && <ReactMarkdown>{introSection?.content}</ReactMarkdown>}
        </Container>
      </section>
    )}

    <Container>
      <CompanyCard cardData={companyCards.visionCard} />
      <CompanyCard cardData={companyCards.missionCard} />
      <CompanyCard cardData={companyCards.valueCard} />
      <CompanyCard cardData={companyCards.storyCard} />
    </Container>
  </>
)

IndexBody.propTypes = propTypes
export default IndexBody
